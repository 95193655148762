async function contentbuilder() {
  const blocks = await loadJs([".content-builder"]);
  blocks.forEach((block) => {
    // init Swiper:
    const swiperContainer = block.querySelector(
      ".pl-relationship__group-swiper"
    );

    if (swiperContainer) {
      // init Swiper:
      const swiper = new Swiper(swiperContainer, {
        direction: "horizontal",
        loop: true,
        autoHeight: false,
        slidesPerView: 1,
        navigation: {
          nextEl: block.querySelector(".swiper-button-next"),
          prevEl: block.querySelector(".swiper-button-prev"),
        },
        breakpoints: {
          640: {
            slidesPerView: 2,
          },
          992: {
            slidesPerView: 3,
          },
        },
      });
    }

    block.classList.add("loaded");
  });
}
