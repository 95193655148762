// function commonCleanup() {
//   initializePage();
//   var screen = document.querySelectorAll(".splash");

//   setCookie("pl-firstload", "true", screen[1].dataset.cookieDuration);
//   const spinner = document.querySelector(".pl-loader");
//   spinner.style.display = "none";
// }

// // Helper function to get the value of a cookie
// function getCookie(name) {
//   const value = `; ${document.cookie}`;
//   const parts = value.split(`; ${name}=`);
//   if (parts.length === 2) return parts.pop().split(";").shift();
// }
// // Helper function to set a cookie with a given name, value, and expiration time in minutes
// function setCookie(name, value, minutes) {
//   const date = new Date();
//   date.setTime(date.getTime() + minutes * 60 * 1000);
//   const expires = `expires=${date.toUTCString()}`;
//   document.cookie = `${name}=${value};${expires};path=/`;
// }

// document.addEventListener("DOMContentLoaded", function () {
//   var splashScreen = document.querySelectorAll(".splash");
//   var deviceType = window.innerWidth >= 1200 ? "desktop" : "mobile";
//   var hasActive = false;

//   const firstLoad = getCookie("pl-firstload");
//   if (firstLoad !== "true") {
//     for (var i = 0; i < splashScreen.length; i++) {
//       if (splashScreen[i].classList.contains("splash__" + deviceType)) {
//         splashScreen[i].classList.add("active");
//         hasActive = true;
//       }
//     }

//     if (!hasActive && splashScreen.length > 0) {
//       splashScreen[0].classList.add("active");
//     }

//     var splashTypeContainer = document.querySelector(
//       ".splash.active .splash__container"
//     );

//     var splashType = splashTypeContainer.dataset.splashType;
//     if (splashType == "logo") {
//       setTimeout(function () {
//         for (var i = 0; i < splashScreen.length; i++) {
//           splashScreen[i].classList.remove("active");
//           commonCleanup();
//         }
//       }, splashTypeContainer.dataset.splashDuration * 1000);
//     }

//     // If the splash screen is a video, set the timeout to end on the video's duration
//     if (splashType == "video") {
//       var video = document.getElementById("splash-video");
//       video.onloadedmetadata = function () {
//         setTimeout(function () {
//           for (var i = 0; i < splashScreen.length; i++) {
//             splashScreen[i].classList.remove("active");
//             commonCleanup();
//           }
//         }, video.duration * 1000);

//         setTimeout(function () {
//           for (var i = 0; i < splashScreen.length; i++) {
//             video.classList.add("fade-out");
//           }
//         }, video.duration * 1000 - 1000);
//       };
//     }
//   } else {
//     commonCleanup();
//   }
// });
