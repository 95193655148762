function initializePage() {
  siteheader();
  ctablock();
  cover();
  featuredcontent();
  servicesaccordion();
  splitcontent();
  sitefooter();
  contentbuilder();
  cardsbuilder();
}
document.addEventListener("DOMContentLoaded", function () {
  initializePage();
});

document.querySelectorAll('a[href^="#"]').forEach((anchor) => {
  anchor.addEventListener("click", function (e) {
    e.preventDefault();

    document.querySelector(this.getAttribute("href")).scrollIntoView({
      behavior: "smooth",
    });
  });
});
