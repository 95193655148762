async function servicesaccordion() {
  const blocks = await loadJs([".services-accordion"]);
  blocks.forEach((block) => {
    // const block = document.querySelectorAll("." + elm.className + "");
    // console.log(block);
    const blockId = block.dataset.id;

    const menuWrapper = block.querySelector(
      ".services-accordion__menu-wrapper"
    );
    const contentWrapper = block.querySelector(
      ".services-accordion__content-wrapper"
    );
    const menuItem = block.querySelectorAll(".services-accordion__menu-item");
    const contentItem = block.querySelectorAll(
      ".services-accordion__content-item"
    );

    for (let i = 0; menuItem.length; i++) {
      menuItem[i].addEventListener("click", (el) => {
        // console.log(el.target.parentNode.parentNode);
        let parent = el.target.parentNode.parentNode;
        let accordionId = el.target.dataset.accordionId;
        // console.log(parent.dataset.id);
        for (let i = 0; i < contentItem.length; i++) {
          if (parent.dataset.id === blockId) {
            if (contentItem[i].dataset.contentId === accordionId) {
              contentItem[i].classList.add("active");
              el.target.classList.add("active");
            } else if (contentItem[i].dataset.contentId != accordionId) {
              el.target.classList.remove("active");
              contentItem[i].classList.remove("active");
            }
          }
        }
      });
    }

    // console.log(elm.dataset.id);

    block.classList.add("loaded");
  });
}
