async function loadJs(classes) {
  return new Promise((resolve) => {
    const blocks = Array.from(document.querySelectorAll(classes.join(",")));
    if (blocks.length > 0) {
      return resolve(blocks);
    }

    const observer = new MutationObserver((mutations) => {
      const blocks = Array.from(document.querySelectorAll(classes.join(",")));
      if (blocks.length > 0) {
        resolve(blocks);
        observer.disconnect();
      }
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });
  });
}
