async function siteheader() {
  const blocks = await loadJs([".site-header"]);
  blocks.forEach((block) => {
    const hamburgerIcon = document.getElementById("hamburger-icon");
    const mainNav = document.querySelector(".site-header__nav-wrapper--mobile");

    hamburgerIcon.addEventListener("click", function () {
      hamburgerIcon.classList.toggle("active");
      mainNav.classList.toggle("active");
      document.body.classList.toggle("menu-open");
      console.log("tet");
    });

    block.classList.add("loaded");
  });
}
