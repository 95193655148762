async function cardsbuilder() {
  const blocks = await loadJs([".cards-builder"]);
  blocks.forEach((block) => {
    const teamCards = document.querySelectorAll(".team-card__item");
    const popups = document.querySelectorAll(".popup");
    const closeButtons = document.querySelectorAll(".popup__close");
    const popupOverlay = document.querySelector(".popup-overlay");
    const body = document.querySelector("body");

    teamCards.forEach((card) => {
      const cardId = card.id;
      const popupId = `popup-${cardId}`;
      const popup = document.getElementById(popupId);

      card.addEventListener("click", () => {
        console.log("ello");
        popups.forEach((p) => {
          p.style.display = "none";
        });
        popup.style.display = "block";
        popup.style.opacity = "0";
        popupOverlay.style.display = "block";
        popupOverlay.style.opacity = "0";
        body.classList.add("popup-active");

        setTimeout(() => {
          popup.style.opacity = "1";
          popup.classList.add("active");
          popupOverlay.style.opacity = "1";
          popupOverlay.classList.add("active");
        }, 1);
      });
    });

    closeButtons.forEach((button) => {
      button.addEventListener("click", (event) => {
        const popup = event.target.closest(".popup");
        popup.style.display = "none";
        popupOverlay.style.display = "none";
        body.classList.remove("popup-active");
      });
    });
    block.classList.add("loaded");
  });
}
